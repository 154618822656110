import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";

/* eslint-disable react-refresh/only-export-components */
import HdPageLoader from "@hd/ui-kit/hd-page-loader/HdPageLoader";

const LoginLayoutPage = lazy(() => import("./pages/login-layout/LoginLayout"));
const LoginPage = lazy(() => import("./pages/login/LoginPage"));
const ForgotPasswordPage = lazy(
  () => import("./pages/forgot-password/ForgotPasswordPage")
);
const ResetPasswordPage = lazy(
  () => import("./pages/reset-password/ResetPasswordPage")
);
const RegisterPage = lazy(() => import("./pages/register/RegisterPage"));
const UnsubscribePage = lazy(
  () => import("./pages/unsubscribe/UnsubscribePage")
);

function useLoginRoutes() {
  return (
    <React.Fragment>
      <Route
        path="account"
        element={
          <Suspense fallback={<HdPageLoader />}>
            <LoginLayoutPage />
          </Suspense>
        }
      >
        <Route
          path="login"
          element={
            <Suspense fallback={<HdPageLoader />}>
              <LoginPage />
            </Suspense>
          }
        />
        <Route
          path="forgot-password"
          element={
            <Suspense fallback={<HdPageLoader />}>
              <ForgotPasswordPage />
            </Suspense>
          }
        />
        <Route
          path="reset-password/:token"
          element={
            <Suspense fallback={<HdPageLoader />}>
              <ResetPasswordPage />
            </Suspense>
          }
        />
        <Route
          path="register/:token"
          element={
            <Suspense fallback={<HdPageLoader />}>
              <RegisterPage />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="unsubscribe"
        element={
          <Suspense fallback={<HdPageLoader />}>
            <LoginLayoutPage />
          </Suspense>
        }
      >
        <Route
          path="me/:token"
          element={
            <Suspense fallback={<HdPageLoader />}>
              <UnsubscribePage />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<HdPageLoader />}>
              <>Missing token!</>
            </Suspense>
          }
        />
      </Route>
    </React.Fragment>
  );
}

export default useLoginRoutes;
